import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EventObj } from '@tinymce/tinymce-angular/editor/Events';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Editor, EditorEvent, RawEditorSettings } from 'tinymce';
import { LoaderService } from 'src/app/services/loader.service';
import { linkify } from 'src/app/resources/shared-functions';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-quill-editor',
  templateUrl: 'quill-editor.component.html',
  styleUrls: ['./quill-editor.component.scss']
})
export class QuillEditorComponent implements OnInit, OnDestroy {

  // Do not upgrade TinyMCE library to version 6
  // because from that version pasting formats from Microsoft Word isn't available in free version

  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() label: string;
  @Input() textFormControl: FormControl;
  @Input() toolbarPositionBottom = false;
  @Input() showStandardToolbar = true;
  @Input() showLinkPlugin = false;
  @Input() useContentSize = false;
  @Input() useTextAlignement = false;
  @Input() enablePasteAsText = false;
  @Input() height: number;
  @Input() validateTextForm = false;
  @Input() optionalField = false;
  @Input() set setupSignature(useSignature: boolean) {
    if (useSignature) {
      this.config.setup = (editor: Editor) => {
        editor.on('NewBlock', (event: EditorEvent<any>) => this.setStyles(event, editor));
        editor.on('ExecCommand', (event: EditorEvent<any>) => this.setStyles(event, editor));
        editor.on('SetContent', (event: EditorEvent<any>) => this.setStyles(event, editor));
      };
    }
  }
  @Input() classList: string;
  showEditor = true;
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  config: RawEditorSettings = {
    branding: false,
    elementpath: false,
    menubar: false,
    selector: 'textarea',
    plugins: ['paste lists', 'autolink'],
    paste_preprocess: (plugin, args) => {
      if (!args.content.includes('</a>')) {
        args.content = linkify(args.content);
      }
    },
    min_height: 175,
    height: 300,
    body_class: 'ql-editor',
    content_css: (document.querySelector('link[rel=stylesheet][href^=styles][href*=css]') as any)?.href,
    fontsize_formats: '10px 13px 18px 32px',
    block_formats: 'Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6; Normal=p',
    toolbar: '',
    placeholder: this.translateService.instant('SHARED.QUILL_PLACEHOLDER'),
    browser_spellcheck : true,
    contextmenu: false
  };

  defaultStyles = 'margin: 0; padding: 0; ';

  styles = {
    P:  `${this.defaultStyles} font-size: 13px; line-height: 1.4;`,
    UL: `${this.defaultStyles} font-size: 13px; line-height: 1.4;  padding-left: 1.5em;`,
    OL: `${this.defaultStyles} font-size: 13px; line-height: 1.4;  padding-left: 1.5em;`,
    LI: `${this.defaultStyles} font-size: 13px; line-height: 1.4;  padding-left: 1.5em;`,
    H1: `${this.defaultStyles} font-size: 26px; line-height: 1.17; font-weight: 500`,
    H2: `${this.defaultStyles} font-size: 20px; line-height: 1.17; font-weight: 500`,
    H3: `${this.defaultStyles} font-size: 15px; line-height: 1.17; font-weight: 500`,
    H4: `${this.defaultStyles} font-size: 13px; line-height: 1.17; font-weight: 500`,
    H5: `${this.defaultStyles} font-size: 11px; line-height: 1.17; font-weight: 500`,
    H6: `${this.defaultStyles} font-size: 9px;  line-height: 1.17; font-weight: 500`,
  };

  constructor(
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef
  ) { }

  get templateFormControl(): FormControl {
    if (this.validateTextForm) {
      return this.textFormControl;
    }
    return this.form.get(this.controlName) as FormControl;
  }

  setStyles(event: EditorEvent<any>, editor: Editor): void {
    const currentElement = editor.selection.getNode();
    const { nodeName, parentElement } = currentElement;

    if (nodeName === 'SPAN' && this.styles.hasOwnProperty(parentElement.nodeName)) {
      this.setAttributes(parentElement);
      return;
    }

    if (this.styles.hasOwnProperty(nodeName)) {
      if (nodeName === 'LI') {
        this.setAttributes(currentElement.parentElement);
      }

      this.setAttributes(currentElement);
    }
  }

  setAttributes(element: Element): void {
    const { nodeName } = element;

    element.setAttribute('style', this.styles[nodeName]);
    element.setAttribute('data-mce-style', this.styles[nodeName]);
  }

  ngOnInit(): void {
    this.loaderService.show();

    if (this.height) {
      this.config.height = this.height;
      if (this.height < this.config.min_height) {
        this.config.min_height = this.height;
      }
    }

    if (this.showStandardToolbar) {
      this.config.toolbar = 'bold italic underline numlist bullist heading fontsizeselect formatselect';
    }

    if (this.useTextAlignement) {
      this.config.toolbar = this.config.toolbar + ' alignleft aligncenter alignright alignjustify';
    }

    if (this.useContentSize) {
      (this.config.plugins as string[]).push('autoresize');
    }

    if (this.showLinkPlugin) {
      this.config.plugins = this.config.plugins + ' link';
      this.config.toolbar = this.config.toolbar + ' link';
    }

    if (this.enablePasteAsText) {
      this.config.paste_as_text = true;
    }

    if (document.body.classList.contains('high-contrast')) {
      this.config.body_class = this.config.body_class + ' high-contrast';
    }


    if (this.showLinkPlugin) {
      if (this.translateService.currentLang !== 'en') {
        this.setLanguage(this.translateService.currentLang);
      }

      this.detectLangChange();
    }
  }

  detectLangChange(): void {
    this.translateService.onLangChange
    .pipe(
      takeUntil(this._ngUnsubscribe$)
    )
    .subscribe(({lang}: LangChangeEvent) => {
      this.showEditor = false;
      this.cdr.detectChanges();

      if (lang === 'en') {
        this.config.language = undefined;
        this.config.language_url = undefined;
      }
      if (lang !== 'en') {
        this.setLanguage(lang);
      }
      this.showEditor = true;
      this.cdr.detectChanges();
    });
  }

  setLanguage(language: string): void {
    this.config.language = language;
    this.config.language_url = `assets/tiny-mce-langs/${language}.js`;
  }

  onEditorChange(event: EventObj<any>): void {
    if (this.textFormControl) {
      this.textFormControl.markAsTouched();
      const textContent = event.editor.getContent({format: 'text'});
      this.textFormControl.setValue(textContent.replace(/\u00A0/g, ' ').trim());
      this.cdr.detectChanges();
    }
  }

  hideLoader(): void {
    this.loaderService.hide();
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
