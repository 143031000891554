<div [formGroup]="form" class="form-group" *ngIf="showEditor" [ngClass]="classList">

  <label *ngIf="label">{{ label | translate }}<em *ngIf="optionalField">({{ 'SHARED.OPTIONAL' | translate }})</em></label>

  <editor
    *ngIf="controlName"
    [init]="config"
    class="tinymce-editor"
    [ngClass]="{
      'no-header': !showStandardToolbar && !showLinkPlugin,
      'toolbar-position-bottom': toolbarPositionBottom,
      'is-invalid': templateFormControl.touched && (templateFormControl.invalid || textFormControl?.invalid)
    }"
    (onInit)="hideLoader()"
    (onChange)="onEditorChange($event)"
    (onKeyUp)="onEditorChange($event)"
    [formControlName]="controlName"
  ></editor>
  <ng-content select="[count]"></ng-content>
  <div *ngIf="templateFormControl.touched && (!!templateFormControl.errors || !!textFormControl?.errors)"
       class="invalid-feedback">
      <span *ngIf="templateFormControl.errors?.required">
          {{ 'SHARED.QUILL_INPUT_REQUIRED_ERROR_MSG' | translate }}
      </span>
      <span *ngIf="!templateFormControl.errors?.required && textFormControl?.errors?.blankCharacterString">
          {{ 'SHARED.QUILL_INPUT_BLANK_CHAR_ERROR_MSG' | translate }}
      </span>
      <span *ngIf="textFormControl?.errors?.maxlength">
        {{ 'GENERIC_ERROR_MESSAGES.MAX_LENGTH' | translate: { maxLength: textFormControl?.errors?.maxlength.requiredLength } }}
      </span>
      <ng-content></ng-content>
  </div>

</div>
