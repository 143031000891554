import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { VideoExtensions } from 'src/app/model/video.interface';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../loader.service';

@Injectable({providedIn: 'root'})
export class VideoService {

  private _apiServerUrl = `https://${environment.mediaServerUrl}`;
  private _restPort = '8444';
  private _apiToken = 'aGlnaGVyOjIwMTdzcHhQWFAu';

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private loaderService: LoaderService
  ) { }

  uploadVideo(file: File): Observable<HttpEvent<string>> {
    const headers = new HttpHeaders({
      apptoken: localStorage.getItem('token')
    });

    const formData = new FormData();
    formData.append('nativeCam', file);

    return this.http
      .post(
        `${this._apiServerUrl}/rest/defaultHigher/upload`,
        formData,
        {
          headers,
          responseType: 'text',
          reportProgress: true,
          observe: 'events'
        }
      );
  }

  startRecording(streamId: string, streamName: string): Observable<string> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Basic ${this._apiToken}`
    });

    return this.http
      .post(
        `${this._apiServerUrl}:${this._restPort}/rest-api/stream/startRecording`,
        { mediaSessionId: streamId } ,
        { headers, responseType: 'text' }
      ).pipe(
        map(() => {
          const fileName = `stream-${streamName}-${streamId}`;

          return `${this._apiServerUrl}/records/${fileName}`;
        })
      );
  }

  mergeVideos(recordedVideo: string, uploadedVideo: string): Observable<HttpEvent<string>> {
    const headers = new HttpHeaders({
      apptoken: localStorage.getItem('token')
    });
    let params = new HttpParams();
    params = params.append('stream', recordedVideo);
    params = params.append('upload', uploadedVideo);

    return this.http
      .get(
        `${this._apiServerUrl}/rest/defaultHigher/merge`,
        {
          params,
          headers,
          responseType: 'text',
          reportProgress: true,
          observe: 'events'
        }
      );
  }

  checkMergeVideos(recordedVideo: string, uploadedVideo: string): {recordedVideo: string, uploadedVideo: string} {
    const uploadedVideoIndex = uploadedVideo.indexOf(VideoExtensions.upload);
    const uploadedURLname = uploadedVideoIndex != -1 ? uploadedVideo.substring(uploadedVideoIndex) : null;
    const recordedVideoIndex = recordedVideo.indexOf(VideoExtensions.stream);
    const recordedURLname = recordedVideoIndex != -1 ? recordedVideo.substring(recordedVideoIndex) : null;
    if (!recordedURLname || !uploadedURLname) {
      this.toastr.error(this.translateService.instant('VIDEO_RECORDER.MERGE_VIDEOS_ERR_MSG'));
    }
    return {
      recordedVideo: recordedURLname,
      uploadedVideo: uploadedURLname
    };
  }

  removeVideo(videoUrl: string): Observable<any> {
    if (!videoUrl) {
      return of(true);
    }

    const tmp = videoUrl.split('/');
    const videoName = tmp[tmp.length - 1];

    return this.http.delete(`${this._apiServerUrl}/rest/defaultHigher/delete/${videoName}`);
  }

  checkVideo(videoName: string): Observable<any> {
    return this.http.head(`${this._apiServerUrl}/rest/defaultHigher/check/${videoName}`)
      .pipe(
        catchError(error => {
          this.loaderService.hide();
          return throwError(() => error);
        })
      );
  }


  checkVideoList(videoList: string[]): Observable<any> {
    const headers = new HttpHeaders({
      apptoken: localStorage.getItem('token')
    });
    const listToSend = Object.assign({}, videoList);
    return this.http.post(`${this._apiServerUrl}/rest/defaultHigher/checkList`, listToSend, { headers, responseType: 'json' })
      .pipe(
        catchError(error => {
          this.loaderService.hide();
          return throwError(() => error);
        })
      );
  }
}
