import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { switchMap, map, tap, catchError } from 'rxjs/operators';
import { AvailableCustomerPerTimeslot, HiddenFromCoustomer, ICreatingGroupInterview,
         IGroupInterview,
         IGroupInterviewForCandidate,
         IGroupInterviewParticipants,
         InterviewStatus } from '../model/interview.interface';
import { ENTERPRISE_ROLES } from '../resources/roles';
import { UserService } from './user.service';
import { VideoService } from './video/video.service';
import { BaseVideoUtilsService } from './base-video-utils.service';
import { dateToUTCString } from '../resources/shared-functions';
import { Roles } from '../model/role.interface';
import { ErrorHandlingService } from './handle-error.service';
import { SetupService } from './setup.service';

@Injectable({ providedIn: 'root' })
export class GroupInterviewService {
  private _interviewsInThePast: IGroupInterview[] = [];
  private _inrerviewsInThePast$: Subject<IGroupInterview[]> = new Subject<IGroupInterview[]>();

  get interviewsInThePast(): Observable<IGroupInterview[]> {
    return this._inrerviewsInThePast$.asObservable();
  }


  constructor(
    private http: HttpClient,
    private userService: UserService,
    private videoService: VideoService,
    private baseVideoService: BaseVideoUtilsService,
    private errorHandlingService: ErrorHandlingService,
    private setupService: SetupService
  ) {}

  createGroupVideoInterview(newInterview: ICreatingGroupInterview): Observable<IGroupInterview> {
    return this.http.post<IGroupInterview>(`${environment.groupVideoInterview}`, newInterview);
  }

  getGroupVideoInterviews(period?: {startDate: Date, endDate: Date}): Observable<IGroupInterview[]> {

    let params = new HttpParams();
    if (period && period.startDate) {
      params = params.append('startDate', dateToUTCString(period.startDate));
      params = params.append('endDate', dateToUTCString(period.endDate));
    }

    const endpoint = ENTERPRISE_ROLES.includes(this.userService.role) ? environment.enterpriseUsers : environment.customers;

    return this.http.get<IGroupInterview[]>(`${endpoint}/${this.userService.userId}/group_video_interviews`, {params})
    .pipe(
      map((interviewList: IGroupInterview[]) => this.manageInteviewList(interviewList)),
    );
  }

  getGroupVideoInterviewsForOtherCustomer(userId: number): Observable<IGroupInterview[]> {
    const endpoint = environment.customers;

    return this.http.get<IGroupInterview[]>(`${endpoint}/${userId}/group_video_interviews`)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(errorResponse)
        ),
        map((interviewList: IGroupInterview[]) => {
          const filteredInterviewList = interviewList.filter((interview) => {
            if (interview.status === InterviewStatus.cancelled) {
              this._interviewsInThePast.push(interview);
            }
            return interview.status !== InterviewStatus.cancelled;
          });
          return this.manageInteviewList(filteredInterviewList).filter((interview) => {
            if (interview.status === InterviewStatus.confirmed || interview.status === InterviewStatus.created) {
              const dateNow = new Date();
              const interviewEnd = interview.timeSlot.end;
              const checkIfNotPast = interviewEnd.getTime() > dateNow.getTime();
              if (!checkIfNotPast) {
                this._interviewsInThePast.push(interview);
              }
              return checkIfNotPast;
            }
            return true;
          });
        }),
        tap(() => this._inrerviewsInThePast$.next(this._interviewsInThePast))
      );
  }

  getAllCustomersPerTimeslot(videoInterviews: IGroupInterview[]): Observable<IGroupInterview[]> {
    if (!videoInterviews.length) {
      return of([]);
    }

    const requests$ = videoInterviews
      .map((videoInterview: IGroupInterview) => this.getAvailableCustomersPerTimeslot(videoInterview));

    return forkJoin(requests$)
      .pipe(
        map((availableCustomers) => videoInterviews.map((interview, index)=>{
          interview.availableCustomers = availableCustomers[index];
          return interview;
        })),
      );
  }

    getAvailableCustomersPerTimeslot(videoInterview: IGroupInterview): Observable<AvailableCustomerPerTimeslot[]> {
      let params = new HttpParams();
      const { start, end } = videoInterview.timeSlot;
      params = params.append('start', dateToUTCString(start));
      params = params.append('end', dateToUTCString(end));
      return this.http.get<AvailableCustomerPerTimeslot[]>(
        `${environment.company}/${this.setupService.companyGuid}/employees_available`, {params})
        .pipe(
          catchError((errorResponse: HttpErrorResponse) =>
            this.errorHandlingService.handleBackendError(errorResponse)
          ),
          map((availableCustomers: AvailableCustomerPerTimeslot[]) => {
            return availableCustomers.filter((availableCustomer) => {
              if (ENTERPRISE_ROLES.includes(availableCustomer.roles[0])) {
                return this.checkIsHiddenFrom(availableCustomer, videoInterview);
              } else {
                return this.checkJobEmployeeRelation(availableCustomer, videoInterview);
              }
            });
          })
        );
  }

  checkJobEmployeeRelation(availableCustomer: AvailableCustomerPerTimeslot, videoInterview: IGroupInterview): boolean {
    const { job } = videoInterview;
    const creatorGuiduserGuid = videoInterview.creatorCustomer?.guid || videoInterview.creatorEnterpriseManager?.guid;
    if (availableCustomer.guid === creatorGuiduserGuid) {
      return false;
    }
    const checkBranch = availableCustomer.roles[0] === Roles.companyHR ?
                          true : availableCustomer.branches.some(branch => branch.id === job.branch.id);
    const checkIsHidden = this.checkIsHiddenFrom(availableCustomer, videoInterview);

    return checkBranch && checkIsHidden ? true: false;
  }

  checkIsHiddenFrom(availableCustomer: AvailableCustomerPerTimeslot, videoInterview: IGroupInterview): boolean {
    const { job } = videoInterview;
    return !job.hiddenFromCustomers?.find(({guid}: HiddenFromCoustomer) => availableCustomer.guid === guid) &&
           !job.hiddenFromEnterpriseManagers?.find(({guid}: HiddenFromCoustomer) => availableCustomer.guid === guid);
  }

  manageInteviewList(interviewList: IGroupInterview[]): IGroupInterview[] {
    interviewList.map(interview => {
      interview.timeSlot.start = interview.timeSlot.start ? new Date(interview.timeSlot.start) : null;
      interview.timeSlot.end = interview.timeSlot.end ? new Date(interview.timeSlot.end) : null;
    });
    return interviewList;
  }

  getGroupVideoInterview(guid: string): Observable<IGroupInterview> {
    return this.http.get<IGroupInterview>(`${environment.groupVideoInterviews}/${guid}`);
  }

  getGroupVideoInterviewForCandidate(guid: string): Observable<IGroupInterview> {
    return this.http.get<IGroupInterview>(`${environment.api}/unauthorized/group_video_interviews/${guid}`);
  }

  manageGroupInterview(interview: IGroupInterview): IGroupInterview {
    interview.timeSlot.start = interview.timeSlot.start ? new Date(interview.timeSlot.start) : null;
    interview.timeSlot.end = interview.timeSlot.end ? new Date(interview.timeSlot.end) : null;
    interview.customersInGroupInterview = [...interview.customersInGroupInterview, ...interview.enterpriseManagersInGroupInterview];
    interview.status = interview.status;
    delete interview.enterpriseManagersInGroupInterview;
    return interview;
  }

  manageGroupInterviewForCandidate(interview: IGroupInterviewForCandidate): IGroupInterviewForCandidate {
    const { groupInterview } = interview;
    groupInterview.timeSlot.start = groupInterview.timeSlot.start ? new Date(groupInterview.timeSlot.start) : null;
    groupInterview.timeSlot.end = groupInterview.timeSlot.end ? new Date(groupInterview.timeSlot.end) : null;
    return interview;
  }

  getGroupVideoInterviewByCandidate(candidateGuid: string): Observable<IGroupInterviewForCandidate> {
    return this.http.get<IGroupInterviewForCandidate>(`${environment.candidateInGroupInterview}/${candidateGuid}`);
  }

  getGroupVideoInterviewByThirdParty(thirdPartyGuid: string): Observable<IGroupInterviewForCandidate> {
    return this.http.get<IGroupInterviewForCandidate>(`${environment.thirdPartyInGroupInterview}/${thirdPartyGuid}`);
  }

  confirmOrCancelGroupVideoInterviewByCustomer(guid: string, confirm: boolean): Observable<string> {
    return this.http.post<string>(`${environment.customerInGroupInterview}/${guid}`, { confirm });
  }

  confirmOrCancelGroupVideoInterviewByEnterpriseManager(guid: string, confirm: boolean): Observable<string> {
    return this.http.post<string>(`${environment.enterpriseManagerInGroupInterview}/${guid}`, { confirm });
  }

  confirmOrCancelGroupVideoInterviewByCandidate(guid: string, confirm: boolean): Observable<string> {
    return this.http.post<string>(`${environment.candidateInGroupInterview}/${guid}`, { confirm });
  }

  confirmOrCancelGroupVideoInterviewByThirdParty(guid: string, confirm: boolean): Observable<string> {
    return this.http.post<string>(`${environment.thirdPartyInGroupInterview}/${guid}`, { confirm });
  }

  addParticipants(guid: string, newParticipants: IGroupInterviewParticipants): Observable<IGroupInterview> {
    return this.http.post<IGroupInterview>(`${environment.groupVideoInterview}/${guid}/add`, newParticipants);
  }

  getGroupInterviewsLibrary(): Observable<IGroupInterview[]> {
    return this.getGroupVideoInterviews()
      .pipe(
        switchMap((interviews) => {
        const filteredInterviews = interviews
          .map((interview) => {
            interview.libraryVideo = this.baseVideoService.generateMixedFileName(interview.guid, false);
            return interview;
          });
        if (!filteredInterviews.length) {
          return of([]) as Observable<IGroupInterview[]>;
        }

        const generatedFilenames = filteredInterviews.map((interview) => interview.libraryVideo);
        return this.findInterviewsLibrary(generatedFilenames, filteredInterviews);
        })
      );
  }

  findInterviewsLibrary(generatedFilenames: string[], interviews: IGroupInterview[]): Observable<IGroupInterview[]> {
    return this.videoService.checkVideoList(generatedFilenames).pipe(
      map((list: string[])=> {
        return interviews
          .filter((interview) => list.includes(interview.libraryVideo))
          .map((interview) => {
            interview.libraryVideo = this.baseVideoService.addPrefixToFileName(interview.libraryVideo);
            return interview;
          });
      }),

    );
  }

  resetInterviewsInThePast(): void {
    this._interviewsInThePast = [];
  }

  acceptTermsAndConditions(guid: string, isExternalUser: boolean ): Observable<string> {
    const endpoint = isExternalUser ? 'third_party_in_group_interview' : 'candidate_in_group_interview';
    return this.http.post<string>(`${environment.api}/${endpoint}/${guid}/accept_terms_and_conditions`, {});
  }
}
